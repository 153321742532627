import { create } from "zustand";

import { PictureState } from "../interface/zustand/picture";

export const usePictureStore = create<PictureState>((set) => ({
  picture: null,
  time: 59,
  intervalId: null,

  quality: "sd",

  bodyType: "",
  bustSize: "Small",
  buttSize: "Big",

  isGenerating: false,
  isDownload: false,
  isUpscaling: "",
  setPicture: (value) => set(() => ({ picture: value })),

  setQuality: (value) => set(() => ({ quality: value })),

  setBodyType: (value) => set(() => ({ bodyType: value })),
  setBustSize: (value) => set(() => ({ bustSize: value })),
  setButtSize: (value) => set(() => ({ buttSize: value })),

  setIsGenerating: (value) => set(() => ({ isGenerating: value })),
  setIsDownload: (value) => set(() => ({ isDownload: value })),
  // @ts-ignore
  setIsUpscaling: (value) => set(() => ({ isUpscaling: value })),
  setIntervalId: (intervalId) => set(() => ({ intervalId })),
  setTime: (time) => set(() => ({ time })),
}));
