import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import {RouterProvider, createRouter, useSearch} from "@tanstack/react-router";

import reportWebVitals from "./reportWebVitals";
import { routeTree } from "./routeTree.gen";

import "./assets/fonts/Mulish-Regular.woff";
import "./assets/fonts/Mulish-Regular.woff2";
import "./index.scss";
// @ts-ignore
import TagManager from 'react-gtm-module'

import { QueryClientProvider, QueryClient } from "react-query";

import "./i18n";
import Cookies from "js-cookie";

export const queryClient = new QueryClient();

const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
