import React, { useEffect, useState } from 'react'
import { createFileRoute, useSearch } from '@tanstack/react-router'
import DragPicture from '../component/dragPicture/DragPicture'
import Footer from '../component/footer/Footer'

import styles from '../assets/styles/indexPage.module.scss'
import SideBar from '../component/sideBar/SideBar'
import AvatarAndBalance from '../component/avatarAndBalance/AvatarAndBalance'
import SettingDownload from '../component/settingDownload/SettingDownload'
import GetFreeCoins from '../component/getFreeCoins/GetFreeCoins'
import Button from '../component/common/button/Button'
import { usePictureStore } from '../store/picture'
import { useUserStore } from '../store/user'

import SocialMedia from '../component/socialMedia/socialMedia'
import CustomizedSettings from '../component/modals/customizedSettings/CustomizedSettings'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie';
// @ts-ignore
import TagManager from 'react-gtm-module'
export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {

  const [isTab, setIsTab] = useState(false)
  const isAuth = useUserStore((state) => state.isAuth)
  const setIsOpenedModalLogin = useUserStore((state) => state.setIsOpenedModalLogin)
  const [isOpen, setIsOpen] = useState(false)
  const isGenerating = usePictureStore((state) => state.isGenerating)
  const GTM_COOKIE_NAME = 'GTM_ID';
  const GTM_QUERY_PARAM = 'gtm';
  const picture = usePictureStore((state) => state.picture)
  const [socket, setSocket] = useState<any>()
  const { t } = useTranslation()
  const setIsPayment = useUserStore((state) => state.setIsPayment)
  const handleDownload = () => {
    setIsOpen(true)
  }

  const handleAddBalance = () => {
    if (isAuth) {
      setIsPayment(true)
    } else {
      setIsOpenedModalLogin(true)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get('subid')
    const exist = Cookies.get('subid')
    if(value != null){
        Cookies.set('subid', value, { expires: 360 });
    }

    const setWindowSize = () => {
      setIsTab(window.matchMedia('(max-width: 1080px)').matches)
    }

    setWindowSize()

    window.addEventListener('resize', setWindowSize)

    return () => {
      window.removeEventListener('resize', setWindowSize)
    }
  }, [])
  const search = useSearch({ strict: false });
  useEffect(() => {
    // Function to get the GTM ID from the query params
    const getGtmIdFromQuery = () => {
      return search[GTM_QUERY_PARAM] || null;
    };

    // Function to initialize GTM
    const initializeGtm = (gtmId: any) => {
      if (gtmId) {
        TagManager.initialize({
          gtmId: gtmId,
        });
      }
    };

    // Get GTM ID from query parameters
    let gtmId = getGtmIdFromQuery();

    if (gtmId) {
      // Save GTM ID to cookie for future use
      Cookies.set(GTM_COOKIE_NAME, gtmId, { expires: 7 }); // Set cookie for 7 days
    } else {
      // If no GTM ID in query params, get it from the cookie
      gtmId = Cookies.get(GTM_COOKIE_NAME);
    }

    // Initialize GTM with the retrieved GTM ID
    if (gtmId) {
      initializeGtm(gtmId);
    }
  }, [search]); // Runs when search query changes
  // TagManager.dataLayer('')
  return (
    <>
      <div className={styles.layout}>
        {isTab && (
          <div className={styles.tab}>
            <AvatarAndBalance  />

            <div className={styles.layout__row}>
              <div className={styles.layout__row_column}>
                <Button text={t('buy_more_gems')} type={'straight'} onClick={handleAddBalance} variant={isTab ? 'normal' : 'big'} />
              </div>

              <div className={styles.layout__row_column}>
                <SettingDownload isTab={isTab}   socket={socket} setSocket={setSocket}/>
              </div>
            </div>
          </div>
        )}

        <div>
          <DragPicture socket={socket} setSocket={setSocket} />

          <div className={styles.tab}>
            <div className={styles.download}>
              <Button text={t('try_fetish_styles')} disabled={isGenerating || !picture} variant={'big'} onClick={handleDownload} />

              <div className={styles.tab__buy}>
                <Button text={t('buy_more_gems')} type={'straight'} onClick={handleAddBalance} variant={'big'} />
              </div>
            </div>

            <div className={styles.get_free}>{/*<GetFreeCoins />*/}</div>

            <SocialMedia />
          </div>

          <Footer />
        </div>

        <SideBar socket={socket} />
      </div>

      <CustomizedSettings isModalOpened={isOpen} hideModal={() => setIsOpen(false)} socket={socket} />
    </>
  )
}
