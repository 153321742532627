import {createFileRoute, useSearch} from "@tanstack/react-router";
import Button from "../../../component/common/button/Button";
import Footer from "../../../component/footer/Footer";

import styles from "../../../assets/styles/godzi-success.module.scss";
import {FormEvent, useEffect} from "react";
import { useNavigate } from "@tanstack/react-router";
import TagManager from "react-gtm-module";
import Cookies from "js-cookie";

function SuccessForm() {
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await navigate({ to: "/" });
  };
  const GTM_COOKIE_NAME = 'GTM_ID';
  const GTM_QUERY_PARAM = 'gtm';

  const search = useSearch({ strict: false });
  useEffect(() => {
    // Function to get the GTM ID from the query params
    const getGtmIdFromQuery = () => {
      return search[GTM_QUERY_PARAM] || null;
    };

    // Function to initialize GTM
    const initializeGtm = (gtmId: any) => {
      if (gtmId) {
        TagManager.initialize({
          gtmId: gtmId,
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'Buy gems',  // Replace with your custom event name
          },
        });
      }
    };

    // Get GTM ID from query parameters
    let gtmId = getGtmIdFromQuery();

    if (gtmId) {
      // Save GTM ID to cookie for future use
      Cookies.set(GTM_COOKIE_NAME, gtmId, { expires: 7 }); // Set cookie for 7 days
    } else {
      // If no GTM ID in query params, get it from the cookie
      gtmId = Cookies.get(GTM_COOKIE_NAME);
    }

    // Initialize GTM with the retrieved GTM ID
    if (gtmId) {
      initializeGtm(gtmId);
    }
  }, [search]); // Runs when search query changes



  return (
    <div>
      <form className={styles.form__success_payment} onSubmit={handleSubmit}>
        <div className={styles.content}>
          <h2>Payment Successful</h2>
          <p>Thank you for your purchase!</p>
          <Button baseType="submit" text="Back Home" />
        </div>
      </form>
      <Footer />
    </div>
  );
}

// @ts-ignore
export const Route = createFileRoute("/godzi/success")({
  component: SuccessForm,
});

export default SuccessForm;
