import { createFileRoute } from "@tanstack/react-router";

import Footer from "../../component/footer/Footer";

import styles from "../../assets/styles/privacy.module.scss";

// @ts-ignore
export const Route = createFileRoute("/termsOfService/")({
  component: TermsOfService,
});

function TermsOfService() {
  return (
    <div>
      <div className={styles.terms}>
        <h1>Terms of Service</h1>

        <p>Last updated: July 15th 2024</p>

        <h2>Definitions</h2>

        <ul>
          <li>
            Affiliate: An entity that controls, is controlled by, or is under
            common control with a party. "Control" means owning 50% or more of
            the voting rights for directors or other management positions.
          </li>
          <li>Country: Refers to the United States.</li>
          <li>
            Company: Refers to nuds.ai (also referred to as "We," "Us," or "Our"
            in this Agreement).
          </li>
          <li>
            Device: Any device capable of accessing the Service, such as a
            computer, smartphone, or tablet.
          </li>
          <li>
            Feedback: Any comments, suggestions, or recommendations you provide
            regarding our Service.
          </li>
          <li>
            Service: The website nuds.ai and its associated functionalities.
          </li>
          <li>
            Premium Mode: A service tier requiring a recurring monthly fee.
          </li>
          <li>Free Mode: A service tier available at no cost.</li>
          <li>
            Image Generation Service: The AI technology used to generate images.
          </li>
          <li>
            Terms of Use: These terms that form the entire agreement between you
            and nuds.ai regarding your use of the Service.
          </li>
          <li>
            Third Party Social Media Service: Services or content provided by
            third parties that may be accessible through our Service.
          </li>
          <li>You: The individual or entity accessing or using the Service.</li>
        </ul>

        <h2>Acceptance of Terms</h2>

        <p>
          These Terms of Use govern your use of our Service. By accessing or
          using the Service, you agree to be bound by these terms. If you do not
          agree, do not use the Service. You must be over 18 years old to use
          our Service. Your use of the Service is also subject to our Privacy
          Policy, which outlines our data collection and usage practices.
        </p>

        <h3>Prohibited Activities</h3>

        <ul>
          <li>Do not upload content you do not own the rights to.</li>
          <li>
            Do not upload hate speech, threats, harassment, defamatory content,
            or content encouraging violence or illegal activities.
          </li>
          <li>
            Do not upload illegal content, including child pornography or
            nonconsensual pornography.
          </li>
          <li>Do not impersonate others.</li>
          <li>
            Do not use the Service to host image libraries for external sites,
            content for websites, advertising, avatars, or as a content delivery
            network.
          </li>
        </ul>

        <p>
          Violations of these rules may result in account termination, deletion
          of your content, reporting to authorities, and a ban from using our
          Service.
        </p>

        <h2>Licensing</h2>

        <p>
          Subject to compliance with these terms, nuds.ai retains all rights to
          the assets created using the Service. You may share these assets under
          the following conditions:
        </p>

        <ul>
          <li>
            Provide proper attribution to nuds.ai, with the watermark considered
            sufficient attribution.
          </li>
          <li>
            Do not share content that violates any laws or third-party rights.
          </li>
          <li>
            Do not use the assets for commercial purposes without written
            consent from nuds.ai.
          </li>
        </ul>

        <h2>Fee Changes</h2>

        <p>
          Nuds.ai reserves the right to change service fees at any time. Any
          changes will take effect immediately upon notice. Continued use of the
          Service after fee changes constitutes acceptance of the new fees.
        </p>

        <h2>Usage Rules</h2>

        <p>
          The Image Generation Service is for personal use only and may not be
          used for commercial purposes without written consent from nuds.ai. You
          agree not to reverse engineer or redistribute the Service without
          permission.
        </p>

        <h2>Third-Party Links</h2>

        <p>
          Our Service may contain links to third-party websites or services.
          Nuds.ai is not responsible for the content, privacy policies, or
          practices of these third-party sites. You access these sites at your
          own risk.
        </p>

        <h2>Termination</h2>

        <p>
          We may terminate or suspend your access to the Service immediately,
          without notice, for any violation of these terms. Upon termination,
          your right to use the Service ends immediately.
        </p>

        <h2>Limitation of Liability</h2>

        <p>
          Nuds.ai's liability is limited to the amount paid by you for the
          Service or $100 if no purchase has been made. We are not liable for
          any indirect, incidental, or consequential damages arising from your
          use of the Service.
        </p>

        <h2>"AS IS" Disclaimer</h2>

        <p>
          The Service is provided "AS IS" and "AS AVAILABLE" without warranties
          of any kind. Nuds.ai disclaims all warranties, including implied
          warranties of merchantability, fitness for a particular purpose, and
          non-infringement. We do not guarantee that the Service will meet your
          requirements, be error-free, or operate without interruptions.
        </p>

        <h2>Governing Law</h2>

        <p>
          These terms are governed by the laws of the State of California,
          except for its conflicts of laws principles.
        </p>

        <h2>Dispute Resolution</h2>

        <p>
          If you have any disputes, you agree to first contact nuds.ai to
          resolve the issue informally.
        </p>

        <h3>For EU Users</h3>

        <p>
          If you are an EU consumer, you benefit from the mandatory provisions
          of your country of residence.
        </p>

        <h3>U.S. Federal Government End Users</h3>

        <p>
          Our Service qualifies as a "Commercial Item" under 48 C.F.R. §2.101.
        </p>

        <h3>U.S. Legal Compliance</h3>

        <p>
          You represent that you are not in a country subject to a U.S.
          government embargo or listed as a "terrorist supporting" country and
          are not on any U.S. government prohibited or restricted parties list.
        </p>

        <h2>Severability</h2>

        <p>
          If any part of these terms is found to be unenforceable or invalid,
          that part will be modified to fulfill its intended purpose, and the
          remaining terms will remain in full force and effect.
        </p>
        <h2>Waiver</h2>

        <p>
          Failure to exercise any right under these terms does not constitute a
          waiver of future rights.
        </p>
        <h2>Refund Policy</h2>

        <p>
          Payments are non-refundable except as required by law. Refund requests
          for subscriptions are evaluated on a case-by-case basis at nuds.ai's
          discretion.
        </p>
        <h2>Translation Interpretation</h2>

        <p>
          These terms may be translated for convenience. In case of a dispute,
          the English version will prevail.
        </p>
        <h2>Section 2257 Compliance</h2>

        <p>
          Nuds.ai is not the primary or secondary producer of any content found
          on the site. Our activities are limited to transmission, storage,
          retrieval, hosting, and formatting of content posted by users.
        </p>
        <h2>Changes to Terms</h2>

        <p>
          We may modify these terms at any time. If changes are material, we
          will provide 30 days' notice. Continued use of the Service after
          changes indicates acceptance of the new terms.
        </p>

        <h2>Contact Information</h2>

        <p>
          For any questions regarding these terms, please contact us at:
          customer@nuds.ai.
        </p>
        <p>
          For business inquiries and our affiliate program, contact us at:
          customer@nuds.ai.
        </p>

        <h2>General Information</h2>

        <p className={styles.lk}>
          N.AI Tech Karol Iannarilli<br/>
          Poznań, Romana Dmowskiego St. 5/7E, 20,<br/><br/>
          60-222, woj. WIELKOPOLSKIE
          POLAND
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default TermsOfService;
