import { Link } from "@tanstack/react-router";

import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.footer__column}>
        <div>
          <Link to={"/makeMoneyWithUs"}>{t("make_money_with")} </Link>
        </div>
        <div>
          <Link to={"/mediaPress"} target={"_blank"}>
            {t("for_media_and")}
          </Link>
        </div>
        <div>
          <br/>
          <br/>
          <br/>
          <p className={styles.lk}>
            N.AI Tech Karol Iannarilli<br/>
            Poznań, Romana Dmowskiego St. 5/7E, 20,<br/><br/>
            60-222, woj. WIELKOPOLSKIE
            POLAND
          </p>



        </div>
      </div>

      <div className={styles.footer__column}>
        <div>
          <Link to={"/privacy"} target={"_blank"}>
            {t("privacy_policy")}
          </Link>
        </div>
        <div>
          <Link to={"/termsOfService"} target={"_blank"}>
            {t("terms_of_service")}
          </Link>
        </div>
        <div>
          <Link to={"/usagePolicy"} target={"_blank"}>
            {t("usage_policy")}
          </Link>
        </div>
      </div>

      <div className={styles.footer__column}>
        <div>
          <Link to={"/"}>{t("contact_us")}</Link>
        </div>
        <div>
          <Link to={"/"}>{t("support")}</Link>
        </div>

      </div>
    </div>
  );
};

export default Footer;
