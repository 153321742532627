import {createFileRoute, useNavigate, useParams} from "@tanstack/react-router";
import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button, createTheme, FormControl, IconButton, InputAdornment, InputLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import moment from "moment/moment";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    useFreeCoinUser,
    useGetTeachCount,
    useGetUserInfo,
} from "../../api/userApi";
import {axiosInstance} from "@/api";
import axios from "axios";

// @ts-ignore
export const Route = createFileRoute("/partners")({
    component: () => <Partners/>,
});


function Partners()
{

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });
    // @ts-ignore
    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") =>
    {
        return amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }

    const { data } = useGetUserInfo();
    const [std, setStd] = useState(moment().format('YYYY-MM-DD 00:00:00'))
    const [etd, setEtd] = useState(moment().format('YYYY-MM-DD 23:59:59'))

    const [cstd, setCstd] = useState(moment().format('YYYY-MM-DD 00:00:00'))
    const [cetd, setCsetd] = useState(moment().format('YYYY-MM-DD 23:59:59'))
    const campaignId = 1;
    const [keitaroConv, setKeitaroConv] = useState([])
    // @ts-ignore
    const getKeitaroEvents = async (cid, start_date, end_date) => {
        const url = `https://af3.nuds.ai/lander/api/index.php?cid=${cid}&from=${start_date}&to=${end_date}`
        return (await axios.get(url, data)).data
    }


    const [keitaroClicks, setKeitaroClicks] = useState([])
    // @ts-ignore
    const getKeitaroClicks = async (cid, start_date, end_date) => {
        const url = `https://af3.nuds.ai/lander/api/clicks.php?cid=${cid}&from=${start_date}&to=${end_date}`
        console.log(url)
        return (await axios.get(url, data)).data
    }
    const [settings, setSettings] = useState(null)
    // @ts-ignore
    const getKeitaroCampaign = async (cid) => {
        const url = `https://af3.nuds.ai/lander/api/settings.php?cid=${cid}`
        return (await axios.get(url, data)).data
    }
    useEffect(() => {
        getKeitaroCampaign(data?.kt_campaign_id).then(response => {
            setSettings(response)
        })
    }, [data]);
    useEffect(() => {
        // const start_date = moment().format('YYYY-MM-DD 00:00:00')
        // const end_date = moment().format('YYYY-MM-DD 23:59:59')
        getKeitaroEvents(data?.kt_campaign_id, std, etd).then(response => {
            // @ts-ignore
            setKeitaroConv([...response['rows']])
        })

    }, [data, std, etd]);

    useEffect(() => {

        getKeitaroClicks(data?.kt_campaign_id, cstd, cetd).then(response => {
            // @ts-ignore
            setKeitaroClicks([...response['rows']])
        })
    }, [data, cetd, cstd]);



    const options = [
        { label: 'Today', id: 1 },
        { label: 'Yesterday', id: 2 },
        { label: 'Current week', id: 3 },
        { label: 'Last 7 days', id: 4 },
        { label: 'Current month', id: 6 },
        { label: 'Previous month', id: 7 },
        { label: 'Date range', id: 8 },
    ];

    // @ts-ignore
    let [selectedEventsRange, setSelectedEventsRange] = useState(options[0])
    let [selectedClicksRange, setSelectedClicksRange] = useState(options[0])
    return <>
        <Box sx={{display: 'flex', width: '100%', flexDirection: {
                xs: 'column', // For extra small screens (xs)
                sm: 'column' // For small and larger screens (sm and above)
            }}}>


            <Box sx={{marginBottom: '20px', marginTop: '20px'}}>
                <TextField disabled value={data?.usdt_wallet == null ? 'loading...' : data.usdt_wallet} size={'small'} label={'USDT TRC20 WALLET'} sx={{color: 'white !important', '-webkit-text-fill-color': "white", width: '500px', backgroundColor: '#302d42', '.Mui-focused':{borderColor: '#1f1d2b !important', color: 'white !important', fontWeight: '600'}   }} placeholder={'USDT TRC20 WALLET'}/>
            </Box>
            <TextField  slotProps={{
                input: {
                    endAdornment: <InputAdornment position="start"><IconButton onClick={() => {
                        try {
                            // @ts-ignore
                            navigator.clipboard.writeText(`${settings['domain']}${settings['alias']}`);
                            console.log('Content copied to clipboard');
                        } catch (err) {
                            console.error('Failed to copy: ', err);
                        }
                    }}><ContentCopyIcon sx={{fill: 'white', color: 'white'}}/></IconButton></InputAdornment>,
                },
            }} label={'Your partner link'}  disabled value={settings == null ? 'loading' : `${settings['domain']}${settings['alias']}`} size={'small'}   sx={{color: 'white !important', '-webkit-text-fill-color': "white", width: '500px', backgroundColor: '#302d42', '.Mui-focused':{borderColor: '#1f1d2b !important', color: 'white !important', fontWeight: '600'}   }}>

            </TextField>
            <Typography sx={{marginBottom: '15px', marginTop: '20px'}}>
                Balance: {formatMoney(data?.balance)}
            </Typography>

        </Box>
        <Box>

        <Box sx={{display: 'flex', gap: '20px',flexDirection: {
                xs: 'column', // For extra small screens (xs)
                sm: 'row' // For small and larger screens (sm and above)
            } }}>

        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant={'h4'} sx={{fontWeight: '600', marginBottom: '10px'}}>Events</Typography>
                <Autocomplete
                    disablePortal
                    size={'small'}
                    value={selectedEventsRange}
                    options={options}
                    onChange={(event, value, reason, details) => {
                        if(value != null) {
                            if(value['id'] == 1) {
                                setStd(moment().format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().format('YYYY-MM-DD 23:59:59'))

                            }
                            if(value['id'] == 2) {
                                setStd(moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'))

                            }
                            if(value['id'] == 3) {
                                setStd(moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 4) {
                                setStd(moment().subtract(7, 'd').format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 6) {
                                setStd(moment().startOf("month").format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().endOf("month").format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 7) {
                                setStd(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD 00:00:00'))
                                setEtd(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59'))
                            }
                            setSelectedEventsRange(value)
                        }
                    }}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} size={'small'}  sx={{color: 'white !important', '-webkit-text-fill-color': "white", width: '200px', backgroundColor: '#302d42', '.Mui-focused':{borderColor: '#1f1d2b !important', color: 'white !important', fontWeight: '600'}   }} label="Period" />}
                />
            </Box>
            <TableContainer sx={{minWidth: 650, height: '600px', backgroundColor: '#302d42'}} component={Paper}>
                <Table sx={{ minWidth: 650, backgroundColor: '#302d42', color: 'white' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>SUBID</TableCell>
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>EVENT TYPE</TableCell>
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>PAYOUT</TableCell>
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>TIME</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keitaroConv.map(row => {
                            return  <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell  sx={{color: 'white', fontWeight: '600'}}>{row['sub_id']}</TableCell>
                                <TableCell  sx={{color: 'white', fontWeight: '600'}}>{row['status'] == 'lead' ? 'Registration' : 'Deposit'}</TableCell>
                                <TableCell  sx={{color: 'white', fontWeight: '600'}}>{formatMoney(row['revenue'])}</TableCell>
                                <TableCell  sx={{color: 'white', fontWeight: '600'}}>{row['postback_datetime']}</TableCell>
                            </TableRow>


                        })}
                    </TableBody>
                </Table>
                {keitaroConv.length <= 0 ? <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '35%'}}>
                    <Typography sx={{color: 'white', fontWeight: '600'}}>
                        NO DATA
                    </Typography>
                </Box> : ''}
            </TableContainer>
            {/*<Button color={'secondary'} variant={'contained'}>*/}
            {/*    lol*/}
            {/*</Button>*/}
        </Box>
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant={'h4'} sx={{fontWeight: '600', marginBottom: '10px'}}>Clicks</Typography>
                <Autocomplete
                    disablePortal
                    size={'small'}
                    value={selectedClicksRange}
                    options={options}
                    onChange={(event, value, reason, details) => {
                        if(value != null) {
                            if(value['id'] == 1) {
                                setCstd(moment().format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().format('YYYY-MM-DD 23:59:59'))

                            }
                            if(value['id'] == 2) {
                                setCstd(moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'))

                            }
                            if(value['id'] == 3) {
                                setCstd(moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 4) {
                                setCstd(moment().subtract(7, 'd').format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 6) {
                                setCstd(moment().startOf("month").format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().endOf("month").format('YYYY-MM-DD 23:59:59'))
                            }
                            if(value['id'] == 7) {
                                setCstd(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD 00:00:00'))
                                setCsetd(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59'))
                            }
                            setSelectedClicksRange(value)
                        }
                    }}

                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} size={'small'}  sx={{color: 'white !important', '-webkit-text-fill-color': "white", width: '200px', backgroundColor: '#302d42', '.Mui-focused':{borderColor: '#1f1d2b !important', color: 'white !important', fontWeight: '600'}   }} label="Period" />}
                />
            </Box>
            <TableContainer sx={{minWidth: 650, height: '600px', backgroundColor: '#302d42'}} component={Paper}>
                <Table sx={{ minWidth: 650, backgroundColor: '#302d42', color: 'white' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>Count</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow

                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{color: 'white', fontWeight: '600'}}>{keitaroClicks.length}</TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
                {keitaroClicks.length <= 0 ? <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '35%'}}>
                    <Typography sx={{color: 'white', fontWeight: '600'}}>
                        NO DATA
                    </Typography>
                </Box> : ''}
            </TableContainer>
            {/*<Button color={'secondary'} variant={'contained'}>*/}
            {/*    lol*/}
            {/*</Button>*/}
        </Box>
        </Box>
        </Box>


    </>
}

export default Partners;