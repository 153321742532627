import ModalWrapper from "../ModalWrapper";
import { FC, useState } from "react";

import Button from "../../common/button/Button";

import beforeAfter from "./assets/main console 7.png";
import { ReactComponent as Birthday } from "./assets/Birthday.svg";
import dressing from "./assets/main console 7-1.png";

import { ModalInterface } from "../../../interface/component/modals/modalsInterface";

import styles from "./Compliance.module.scss";

const Compliance: FC<ModalInterface & { handleAcceptWarning: Function }> = ({
  isModalOpened,
  handleAcceptWarning,
  closable,
}) => {
  const closeWindow = () => {
    window.close();
  };
  const [step, setStep] = useState(1);

  return (
    <ModalWrapper
      isModalOpened={isModalOpened}
      closable={closable}
      close={false}
    >
      <div className={styles.onboarding}>
        {step === 1 && (
          <div className={styles.compliance}>
            <div>
              <h5>Attention</h5>

              <p>
                This site is for users 18+.<br />
                If you're under 18, access is denied. Come back later.<br />
                Uploading images of minors will result in a ban.<br />
              </p>


            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"I'm 18+"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button onClick={closeWindow}>Get out</button>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={styles.compliance}>
            <div>
              <h5>How does the service work?</h5>

              <div className={styles.center}>
                <p>
                  Upload the photo you want to undress and enjoy our welcome
                  bonus
                </p>

                <img src={beforeAfter} alt="" />
              </div>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"Next"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={styles.compliance}>
            <div>
              <h5>Dressing up option</h5>

              <div className={styles.center}>
                <p>Live out your wildest fantasies, be bold in your desires</p>

                <img src={'https://work-images-bucket.s3.eu-north-1.amazonaws.com/main.png?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmV1LW5vcnRoLTEiRjBEAiBpB49NrRZy4EcEteRptCeQvlaiRaTwe3vr0vTpVRdPKQIgLxpJRFI6CRnlxsKPBpnkbcm4bd9CblGr2JcIoJdbjXoq7QII7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwzODE0OTIxNjEwMjciDPyBUM1s%2BOVYa19x6irBAqANGTSZd27ox3rZpknut7weFgxVgNmL2cOkN4AbxlD5qUNXujE14wsDPtdsJhqz3jFFR6pMwTgiAcreMFhoT616Sg9II4%2BgrgtRrxmBRQMz3qToBMdLj6B2gVqx9CacjG0J4HpkwQwAvM8l0C6FxNQyoNpCJEpYAxMpmPc1SY6bMKuiHMN0EZm1JJ9qVHqeYPw%2FSTjF4WN467%2B4FFCZiBCi4BuFtAWXyplTCsQX%2B7XYrCMZd%2BTWdkzEz7BtuH9nqKaKyySni9CUq9svlqhKNDWoOBVbcpGDshc2n7LI12jKIOMJhMB9mZWIhahnWZKuiQ4VhmKfZi1PwZ3ak2XvTSV4hv%2FsFAD6DkqTmC5NrOU2Zw7VWUjnOJ7Sf5PiGmVlEpHMNxKXJ2KhnPXxGrw%2B8348xEpUJFTDQsyXie0LcLB%2B4DDx8Lm4Bjq0Agxt0CyvB3pmiVyDovYz3hTQoiOdmuR%2FMCI1a1vMj3wee9rfaauhG1j06EusT94D3YNeeXBpCJsJ8oXGE1j1bQePPKAjrscdbR6pOrY7%2Bd9%2BAoIAd%2FZjad6UY%2Fwj58hTVrfmzeiV3MRM99mohn9Fiy9q%2B10sHCcy1hj1iFB5%2B0TB7UIhDwxOIpPnsQEXKQfoSiZRKRiI673AkTG%2Fr%2B6W0bxpg2ysysFZsnbTwQE%2FZ9cM4oF4njqnnaTMSR8r7VY8iQsZtiiEtrOaS2MB3DEI0VZ3v3LHLmRzNDoJW6zFU1tFPHHJF2N4x3BYNQy%2B39ruf3wcCqhPuFyJIiO%2FZ1hufHNhnujzzCHnxzhZdUuQv9WkXzSUI%2BF7GcB1d7ZBXXbX92Syo%2FVnwHtPY2qGiSF92gqd3ckD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T141346Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAVRUVUNIBYLVNDB2D%2F20241015%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Signature=702d6f628cf68b3dd483bc09dd5f7da7bcef66c66dfdccc4e1af4e04fd7cccb3'} alt="" />
              </div>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button
                  text={"Next"}
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev + 1);
                  }}
                />
              </div>

              <div className={styles.compliance__get_out}>
                <button
                  onClick={() => {
                    // handleAcceptWarning();
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className={styles.compliance}>
            <div className={styles.vert_center}>
              <Birthday />

              <p>
                Great news! <br />Sign up to receive <span>100 gems</span> as a welcome bonus.

              </p>
            </div>

            <div>
              <div className={styles.compliance__button}>
                <Button text={"Get 100 gems"} onClick={handleAcceptWarning} />
              </div>

              <div className={styles.compliance__get_out}>
                <button onClick={() => setStep(1)}>Go through again</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default Compliance;
